﻿//= require tutors/check_box_form
//= require tutors/flash
//= require tutors/login

$(document).ready(function () {
  //navbar bg on window scroll
  if ($(window).scrollTop() > 200) {
    $(".nav-bar").addClass("coloredBG");
    $(".logo").addClass("show");
  }

  // search-navbar class switch to open the input
   $(".search-form-opener").on("click", function() {
    $(this).prev().toggleClass("opened");
  });

});

//navbar bg on window scroll
$(window).scroll(function scrolledNavStyles() {
  if ($(window).scrollTop() > 120) {
    $(".nav-bar").addClass("coloredBG");
    $(".logo").addClass("show");
  } else {
    $(".nav-bar").removeClass("coloredBG");
    $(".logo").removeClass("show");
  }
});

$(function () {
  $('[data-bs-toggle="tooltip"]').tooltip()
})
